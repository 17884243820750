import { useApp } from '@core/context'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  getEorSettings,
  getPartnerInvoiceFields,
  getPartnerPayrollFrequencies,
} from '@remoteam-front/services'
import { InvoiceFields, PartnerEorSettings } from '@remoteam-front/types/v2'
import { theme } from '@remoteam-front/ui'
import { capitalizeFirstLetter, mapEnum } from '@remoteam-front/utils'
import { DotWave } from '@uiball/loaders'
import { PropsWithChildren } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { v4 } from 'uuid'

export const eorFormId = v4()

export type EorFormType = {
  eorSettings: PartnerEorSettings
  invoiceFields: InvoiceFields
}

type Props = {
  onSubmit(payload: EorFormType): void
} & PropsWithChildren

export const EorSettings = ({ children, onSubmit }: Props) => {
  const { profile } = useApp()
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm<EorFormType>({
    defaultValues: {
      eorSettings: {
        full_time_employment_type_supported: false,
        part_time_employment_type_supported: false,
        permanent_employment_term_supported: false,
        fixed_employment_term_supported: false,
        resident_type_supported: false,
        non_resident_type_supported: false,
        salaried_compensation_type_supported: false,
        hourly_compensation_type_supported: false,
      },
      invoiceFields: { payroll_frequencies: [] },
    },
  })

  const payrollFrequencies = watch(
    'invoiceFields.payroll_frequencies'
  ) as Array<number>

  const partnerPayrollFrequenciesQuery = useQuery(
    [getPartnerPayrollFrequencies.key],
    {
      queryFn: getPartnerPayrollFrequencies.fetch,
    }
  )

  const partnerInvoiceFieldsQuery = useQuery([getPartnerInvoiceFields.key], {
    enabled: !!profile?.partner_profile_id,
    queryFn: () => getPartnerInvoiceFields.fetch(profile!.partner_profile_id),
    onSuccess: ({ payroll_frequencies }) => {
      setValue(
        'invoiceFields.payroll_frequencies',
        payroll_frequencies.map(({ id }) => id)
      )
    },
  })

  const eorSettingsQuery = useQuery([getEorSettings.key], {
    enabled: !!profile?.partner_profile_id,
    queryFn: () => getEorSettings.fetch(profile!.partner_profile_id),
    onSuccess: (response) => setValue('eorSettings', response),
  })

  const handlePayrollFrequencyChange =
    (id: number, isChecked: boolean) => () => {
      const updatedValue = isChecked
        ? [...payrollFrequencies, id]
        : payrollFrequencies.filter((value) => value !== id)

      if (updatedValue.length === 0) {
        return
      }

      setValue('invoiceFields.payroll_frequencies', updatedValue)
    }

  if (
    partnerPayrollFrequenciesQuery.isLoading ||
    partnerInvoiceFieldsQuery.isLoading ||
    eorSettingsQuery.isLoading
  ) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <DotWave />
      </Box>
    )
  }

  return (
    <Stack
      component="form"
      id={eorFormId}
      direction="column"
      spacing={5}
      width={400}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography
            color={theme.palette.secondary[400]}
            fontSize={18}
            marginBottom={2}
          >
            Employment type
          </Typography>
          <Stack
            component={FormControl}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <Box display="flex" alignItems="center" gap="2px">
              <Typography component={FormLabel} color="black" required>
                Full-time
              </Typography>
              <Tooltip title="Employees work the full hours defined by local law or contract, typically 35-40 hours per week.">
                <Box
                  width={16}
                  height={16}
                  component={InfoOutlinedIcon}
                  color={theme.palette.grey[400]}
                />
              </Tooltip>
            </Box>
            <Controller
              control={control}
              name="eorSettings.full_time_employment_type_supported"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    control={<Radio value />}
                    label="Supported"
                  />
                  <FormControlLabel
                    control={<Radio value={false} />}
                    label="Unsupported"
                  />
                </RadioGroup>
              )}
            />
          </Stack>
          <Stack
            component={FormControl}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <Box display="flex" alignItems="center" gap="2px">
              <Typography component={FormLabel} color="black" required>
                Part-time
              </Typography>
              <Tooltip title="Employees work fewer hours than full-time, often with flexible schedules based on hours or days per week.">
                <Box
                  width={16}
                  height={16}
                  component={InfoOutlinedIcon}
                  color={theme.palette.grey[400]}
                />
              </Tooltip>
            </Box>
            <Controller
              control={control}
              name="eorSettings.part_time_employment_type_supported"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value
                    control={<Radio value />}
                    label="Supported"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio value={false} />}
                    label="Unsupported"
                  />
                </RadioGroup>
              )}
            />
          </Stack>
        </Box>

        <Box>
          <Typography
            color={theme.palette.secondary[400]}
            fontSize={18}
            marginBottom={2}
          >
            Employment term
          </Typography>
          <Stack
            component={FormControl}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <Box display="flex" alignItems="center" gap="2px">
              <Typography component={FormLabel} color="black" required>
                Permanent
              </Typography>
              <Tooltip title="Employees have no predetermined end date, offering long-term stability and full access to company benefits with open-ended contracts.">
                <Box
                  width={16}
                  height={16}
                  component={InfoOutlinedIcon}
                  color={theme.palette.grey[400]}
                />
              </Tooltip>
            </Box>
            <Controller
              control={control}
              name="eorSettings.permanent_employment_term_supported"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    control={<Radio value />}
                    label="Supported"
                  />
                  <FormControlLabel
                    control={<Radio value={false} />}
                    label="Unsupported"
                  />
                </RadioGroup>
              )}
            />
          </Stack>
          <Stack
            component={FormControl}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <Box display="flex" alignItems="center" gap="2px">
              <Typography component={FormLabel} color="black" required>
                Fixed
              </Typography>
              <Tooltip title="Employment for a specific, limited period with an end date defined in the contract, often tied to a project or role. Renewal may depend on performance or project needs.">
                <Box
                  width={16}
                  height={16}
                  component={InfoOutlinedIcon}
                  color={theme.palette.grey[400]}
                />
              </Tooltip>
            </Box>
            <Controller
              control={control}
              name="eorSettings.fixed_employment_term_supported"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    control={<Radio value />}
                    label="Supported"
                  />
                  <FormControlLabel
                    control={<Radio value={false} />}
                    label="Unsupported"
                  />
                </RadioGroup>
              )}
            />
          </Stack>
        </Box>

        <Box>
          <Typography
            color={theme.palette.secondary[400]}
            fontSize={18}
            marginBottom={2}
          >
            Residency type
          </Typography>
          <Stack
            component={FormControl}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <Box display="flex" alignItems="center" gap="2px">
              <Typography component={FormLabel} color="black" required>
                Resident
              </Typography>
              <Tooltip title="Employees live and work in the same country, following local tax regulations and employment laws where they reside.">
                <Box
                  width={16}
                  height={16}
                  component={InfoOutlinedIcon}
                  color={theme.palette.grey[400]}
                />
              </Tooltip>
            </Box>
            <Controller
              control={control}
              name="eorSettings.resident_type_supported"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    control={<Radio value />}
                    label="Supported"
                  />
                  <FormControlLabel
                    control={<Radio value={false} />}
                    label="Unsupported"
                  />
                </RadioGroup>
              )}
            />
          </Stack>
          <Stack
            component={FormControl}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <Box display="flex" alignItems="center" gap="2px">
              <Typography component={FormLabel} color="black" required>
                Non-resident
              </Typography>
              <Tooltip title="Employees reside in a different country from where they work, either remotely or as expatriates, and are subject to varying tax and employment regulations.">
                <Box
                  width={16}
                  height={16}
                  component={InfoOutlinedIcon}
                  color={theme.palette.grey[400]}
                />
              </Tooltip>
            </Box>
            <Controller
              control={control}
              name="eorSettings.non_resident_type_supported"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    control={<Radio value />}
                    label="Supported"
                  />
                  <FormControlLabel
                    control={<Radio value={false} />}
                    label="Unsupported"
                  />
                </RadioGroup>
              )}
            />
          </Stack>
        </Box>

        <Box>
          <Typography
            color={theme.palette.secondary[400]}
            fontSize={18}
            marginBottom={2}
          >
            Compensation type
          </Typography>
          <Stack
            component={FormControl}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <Box display="flex" alignItems="center" gap="2px">
              <Typography component={FormLabel} color="black" required>
                Salaried
              </Typography>
              <Tooltip title="Employees receive a fixed, regular payment (monthly, semi-monthly, bi-weekly, etc.) regardless of the number of hours worked.">
                <Box
                  width={16}
                  height={16}
                  component={InfoOutlinedIcon}
                  color={theme.palette.grey[400]}
                />
              </Tooltip>
            </Box>
            <Controller
              control={control}
              name="eorSettings.salaried_compensation_type_supported"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    control={<Radio value />}
                    label="Supported"
                  />
                  <FormControlLabel
                    control={<Radio value={false} />}
                    label="Unsupported"
                  />
                </RadioGroup>
              )}
            />
          </Stack>
          <Stack
            component={FormControl}
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <Box display="flex" alignItems="center" gap="2px">
              <Typography component={FormLabel} color="black" required>
                Hourly
              </Typography>
              <Tooltip title="Employees are paid based on the actual hours worked, with compensation calculated as an hourly wage. This is common for part-time roles.">
                <Box
                  width={16}
                  height={16}
                  component={InfoOutlinedIcon}
                  color={theme.palette.grey[400]}
                />
              </Tooltip>
            </Box>
            <Controller
              control={control}
              name="eorSettings.hourly_compensation_type_supported"
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    control={<Radio value />}
                    label="Supported"
                  />
                  <FormControlLabel
                    control={<Radio value={false} />}
                    label="Unsupported"
                  />
                </RadioGroup>
              )}
            />
          </Stack>
        </Box>

        {/* Payroll frequency */}
        <Box>
          <Typography color={theme.palette.secondary[400]} fontSize={18}>
            Payroll frequency
          </Typography>
          <FormHelperText sx={{ marginBottom: 2 }}>
            Please choose at least one option
          </FormHelperText>
          {partnerPayrollFrequenciesQuery.data?.map(
            ({ id, frequency_name }) => (
              <Stack
                key={id}
                component={FormControl}
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <Typography
                  component={FormLabel}
                  required
                  color="black"
                  error={!!errors.invoiceFields?.invoicing_frequency}
                >
                  {mapEnum(capitalizeFirstLetter(frequency_name))}
                </Typography>

                <Controller
                  control={control}
                  name="invoiceFields.payroll_frequencies"
                  render={({ field }) => (
                    <RadioGroup row value={field.value?.includes(id)}>
                      <FormControlLabel
                        value
                        control={
                          <Radio
                            name={String(id)}
                            value
                            onChange={handlePayrollFrequencyChange(id, true)}
                          />
                        }
                        label="Supported"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            value={false}
                            onChange={handlePayrollFrequencyChange(id, false)}
                          />
                        }
                        label="Unsupported"
                      />
                    </RadioGroup>
                  )}
                />
              </Stack>
            )
          )}
        </Box>
      </Stack>
      {children}
    </Stack>
  )
}
