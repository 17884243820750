/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'react-toastify/dist/ReactToastify.css'
import './Router.scss'

import { useAxiosCall } from '@api/Api'
import AlertMFA from '@atoms/AlertMFA'
import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import ErrorFallback from '@atoms/ErrorFallback'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { OnboardingComponent } from '@components/onboarding/onboarding.component'
import { Sidebar } from '@components/sidebar/sidebar'
import { AppContext } from '@core/context'
import { useKompassify } from '@core/hooks/useKompassify'
import { useKompassifySegments } from '@core/hooks/useKompassifySegments'
import {
  agreements,
  contractTemplates,
  customers,
  documentSyncFilled,
  expenses,
  invoices,
  notificationNone,
  overview,
  payroll,
  payslips,
  requiredDocuments,
  salaryRevision,
  team,
  timeOff,
} from '@core/icons/icons'
import { parseLinkedAccountsResponse } from '@core/utils'
import AdditionalPaymentsPage from '@pages/additional-payments/AdditionalPayments'
import CostCalculator from '@pages/cost-calculator'
import CustomersDetailPage from '@pages/customerDetailPage/CustomersDetailPage'
import CustomersPage from '@pages/customers/CustomersPage'
import EmployeeDetailPage from '@pages/employeeDetailPage/EmployeeDetailPage'
import EmployeesPage from '@pages/employees/EmployeesPage'
import EmployeesExpense from '@pages/employeesExpense/EmployeesExpense'
import { FlagApprovedPayrollListPage } from '@pages/flagApprovedPayrollList/FlagApprovedPayrollListPage'
import { FlagPayrollCompanyListPage } from '@pages/flagPayrollCompanyList/FlagPayrollCompanyListPage'
import FlagPayrollDetailPage from '@pages/flagPayrollsDetailPage/PayrollDetailPage'
// import GlobalEor from '@pages/global-eor/GlobalEor'
import InvoicesPage from '@pages/invoices/InvoicesPage'
import MultiCurrencyPage from '@pages/multi-currency/PayrollDetailPage'
import NewPayrollDetailPage from '@pages/newPayrollsDetailPage/NewPayrollDetailPage'
import OverviewPage from '@pages/overview/OverviewPage'
import PayrollDetailEditPage from '@pages/payrollsDetailEditPage/PayrollDetailEditPage'
import PayrollDetailPage from '@pages/payrollsDetailPage/PayrollDetailPage'
import ProfilePage from '@pages/profile/ProfilePage'
import { TimeOffPage } from '@pages/time-off/time-off.component'
import {
  fetchPartnerProfile,
  fetchPopUpMfa,
  fetchUserProfile,
  postUserReport,
} from '@services/app.service'
import { getFeatureFlagList } from '@services/feature-flag.service'
import { getLinkedAccounts } from '@services/linked-accounts.service'
import { DotWave } from '@uiball/loaders'
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useMutation, useQuery } from 'react-query'
import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import { useAuth } from '../AuthProvider'

const DocumentsPage = React.lazy(() =>
  import('../pages/documents/documents.component')
)
const SalaryChangePage = React.lazy(() => import('@pages/salary-change-page'))
const EmployeePayslipsPage = React.lazy(() =>
  import('@pages/employeePayslips/EmployeePayslips')
)
const SalaryChangeDetailsPage = React.lazy(() =>
  import('@pages/salary-change-details-page')
)

const handleReset = () => {
  window.location.href = '/'
}

const sideBarItems = (showFeature, showFeatureMulti, showNewPayroll) => [
  {
    title: '',
    children: [
      { name: 'Overview', icon: overview, url: 'overview' },
      { name: 'Customers', icon: customers, url: 'customers' },
    ],
  },
  {
    title: 'People',
    children: [
      { name: 'Team', icon: team, url: 'employees' },
      {
        name: 'Time off',
        icon: timeOff,
        url: 'timeoff',
      },
      {
        name: 'Expenses',
        icon: expenses,
        url: 'expense',
      },
      {
        name: 'Salary revision',
        icon: salaryRevision,
        url: 'salary-revision',
      },
    ],
  },
  {
    title: 'Payment',
    children: [
      {
        name: 'Payroll',
        icon: payroll,
        url: 'payrolls',
        hidden: showFeature || showFeatureMulti || showNewPayroll,
      },
      {
        name: 'Payroll',
        icon: payroll,
        url: 'flag_payrolls',
        hidden: !showFeature,
      },
      {
        name: 'Payroll',
        icon: payroll,
        url: 'new_payrolls',
        hidden: showFeatureMulti || !showNewPayroll,
      },
      {
        name: 'Payroll',
        icon: payroll,
        url: 'multi-currency',
        hidden: !showFeatureMulti,
      },
      {
        name: 'Additional payments',
        icon: documentSyncFilled,
        url: 'additional-payments',
      },
      { name: 'Invoices', icon: invoices, url: 'invoices' },
      {
        name: 'Payslips',
        url: 'payslips',
        icon: payslips,
      },
    ],
  },
  {
    title: 'Documents',
    children: [
      {
        name: 'Required documents',
        icon: requiredDocuments,
        url: 'documents/list',
      },
      {
        name: 'Contract templates',
        icon: contractTemplates,
        url: 'documents/templates',
      },
      {
        name: 'Agreements',
        icon: agreements,
        url: 'documents/agreements',
      },
    ],
  },
]

function Pages() {
  const { logout, sessions } = useAuth()
  const [profile, setProfile] = useState()
  const [deactivateVisible, setDeactivateVisible] = useState(false)
  const [countries, setCountries] = useState([])
  const [countriesActive, setCountriesActive] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [currenciesAC, setCurrenciesAC] = useState([])

  const {
    data: userProfile,
    refetch: refetchProfile,
    isLoading,
  } = useQuery('fetchUserProfile', {
    queryFn: fetchUserProfile,
  })

  const getLinkedAccountsQuery = useQuery(
    getLinkedAccounts.key,
    getLinkedAccounts.fetch
  )

  const partnerProfileQuery = useQuery(fetchPartnerProfile.key, {
    queryFn: fetchPartnerProfile.fetch,
    onSuccess: setProfile,
  })

  const [, getCountries] = useAxiosCall(
    {
      url: `countries/?&limit=1000&offset=0&ordering=name`,
    },
    {
      manual: true,
    }
  )
  const [, getCountriesActive] = useAxiosCall(
    {
      url: `countries/?&limit=1000&offset=0&is_forbidden=false&ordering=name`,
    },
    {
      manual: true,
    }
  )
  const [, getCurrencies] = useAxiosCall(
    {
      url: `currencies/?&limit=1000&offset=0&ordering=short_code`,
    },
    {
      manual: true,
    }
  )
  const [, getCurrenciesAC] = useAxiosCall(
    {
      url: `currencies/?&limit=1000&offset=0&allow_for_company=true&ordering=short_code`,
    },
    {
      manual: true,
    }
  )
  const popupMFAQuery = useQuery('popupMFA', fetchPopUpMfa)
  const popupMFAOpen = useBoolean(true)

  const userReport = useMutation('userReport', {
    mutationFn: (body) => postUserReport(body),
  })

  const { data: featureFlag } = useQuery('getFeatureFlagList', () =>
    getFeatureFlagList()
  )
  const getFeature = featureFlag?.filter(
    (ff) => ff.feature_name === 'partial_payroll_approval'
  )
  const getFeatureMulti = featureFlag?.filter(
    (ff) => ff.feature_name === 'partner_multi_currency_invoicing'
  )
  const getFeatureNewPayroll = featureFlag?.filter(
    (ff) => ff.feature_name === 'new_partner_design'
  )
  const showFeature =
    getFeature &&
    getFeature[0] &&
    (getFeature[0].partners_full_access ||
      getFeature[0].partners.some(
        (partner) => partner.id === profile?.partner_profile_id
      ))
  const showFeatureMulti =
    getFeatureMulti &&
    getFeatureMulti[0] &&
    (getFeatureMulti[0].partners_full_access ||
      getFeatureMulti[0].partners.some(
        (partner) => partner.id === profile?.partner_profile_id
      ))
  const showNewPayroll =
    getFeatureNewPayroll &&
    getFeatureNewPayroll[0] &&
    (getFeatureNewPayroll[0].partners_full_access ||
      getFeatureNewPayroll[0].partners.some(
        (partner) => partner.id === profile?.partner_profile_id
      ))

  const hasKompassifyInit = useKompassify(profile)
  const partnerCountry = profile?.legal_address?.living_country?.name

  useKompassifySegments(hasKompassifyInit, partnerCountry)

  useEffect(() => {
    if (
      showFeature &&
      window.location.pathname === '/pages/payrolls' &&
      window.location.pathname !== '/pages/flag_payrolls'
    ) {
      window.location.href = '/pages/flag_payrolls'
    }
  }, [showFeature])

  useEffect(() => {
    if (
      showFeatureMulti &&
      !showNewPayroll &&
      (window.location.pathname === '/pages/payrolls' ||
        window.location.pathname === '/pages/flag_payrolls')
    ) {
      window.location.href = '/pages/multi-currency'
    }
  }, [showFeatureMulti])

  useEffect(() => {
    if (
      showNewPayroll &&
      (window.location.pathname === '/pages/payrolls' ||
        window.location.pathname === '/pages/flag_payrolls')
    ) {
      window.location.href = '/pages/new_payrolls'
    }
  }, [showNewPayroll])

  useEffect(() => {
    getCountries().then((item) => {
      setCountries(item.data.results)
    })
    getCountriesActive().then((item) => {
      setCountriesActive(item.data.results)
    })
    getCurrencies().then((item) => {
      setCurrencies(item.data.results)
    })
    getCurrenciesAC().then((item) => {
      setCurrenciesAC(item.data.results)
    })
  }, [])

  useEffect(() => {
    userReport.mutate({
      event_name: 'active',
      metadata: {
        start_time: new Date(),
      },
    })
  }, [])

  if (
    isLoading ||
    partnerProfileQuery.isLoading ||
    getLinkedAccountsQuery.isLoading
  ) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <DotWave />
      </div>
    )
  }

  // TODO fix me [as page]
  if (partnerProfileQuery.data?.state === 'invited') {
    return (
      <>
        <ToastContainer autoClose={5000} />
        <OnboardingComponent profile={profile} onLogout={logout} />
      </>
    )
  }

  return (
    <section className="pages">
      <ToastContainer autoClose={5000} />
      <AppContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          profile,
          countries,
          currencies,
          countriesActive,
          currenciesAC,
          setProfile,
          userProfile,
          refetchProfile,
          refetchPartnerProfile: partnerProfileQuery.refetch,
        }}
      >
        <Sidebar
          firstName={userProfile?.profile.first_name}
          lastName={userProfile?.profile.last_name}
          items={sideBarItems(showFeature, showFeatureMulti, showNewPayroll)}
          profilePagePath="/pages/profile"
          onLogout={logout}
          linkedAccounts={parseLinkedAccountsResponse(
            getLinkedAccountsQuery.data
          )}
          sessions={sessions}
        />
        <ErrorBoundary FallbackComponent={ErrorFallback} onReset={handleReset}>
          <div className="page-content-wrapper d-flex flex-column justify-content-center">
            <div className="page-main">
              {popupMFAQuery?.data?.mfa_show_popup && popupMFAOpen.value && (
                <AlertMFA onClose={popupMFAOpen.setFalse} />
              )}{' '}
              {userProfile?.state === 'DEACTIVATED' && (
                <div
                  className="acc-deactivate d-flex"
                  onClick={() => setDeactivateVisible(true)}
                >
                  <Icon icon={notificationNone} />
                  <Typography className="text_regular-normal__14 ml-2">
                    Your company’s account has been deactivated.
                  </Typography>
                </div>
              )}
              <div className="page-content">
                <Switch>
                  <Route
                    exact
                    path="/pages/overview"
                    render={() => <OverviewPage />}
                  />
                  <Route
                    exact
                    path="/pages/customers"
                    render={() => <CustomersPage />}
                  />
                  <Route
                    path="/pages/customers/:id"
                    render={() => <CustomersDetailPage />}
                  />
                  <Route
                    exact
                    path="/pages/employees"
                    render={() => <EmployeesPage />}
                  />
                  <Route
                    exact
                    path="/pages/timeoff"
                    render={() => <TimeOffPage />}
                  />
                  <Route
                    exact
                    path="/pages/expense"
                    render={() => <EmployeesExpense />}
                  />
                  <Route
                    exact
                    path="/pages/payslips"
                    render={() => <EmployeePayslipsPage />}
                  />
                  <Route
                    exact
                    path="/pages/salary-revision"
                    render={() => <SalaryChangePage />}
                  />
                  <Route
                    exact
                    path="/pages/salary-revision/:id"
                    render={() => <SalaryChangeDetailsPage />}
                  />
                  <Route
                    exact
                    path="/pages/employees/:id"
                    render={() => <EmployeeDetailPage />}
                  />
                  <Route
                    exact
                    path="/pages/employees/:id/:tabId"
                    render={() => <EmployeeDetailPage />}
                  />
                  <Route
                    exact
                    path="/pages/invoices"
                    render={() => <InvoicesPage />}
                  />
                  <Route
                    exact
                    path="/pages/payrolls"
                    render={() => <PayrollDetailPage />}
                  />
                  <Route
                    exact
                    path="/pages/new_payrolls"
                    render={() => <NewPayrollDetailPage />}
                  />
                  <Route
                    exact
                    path="/pages/multi-currency"
                    render={() => <MultiCurrencyPage />}
                  />
                  <Route
                    exact
                    path="/pages/flag_payrolls"
                    render={() => <FlagPayrollCompanyListPage />}
                  />
                  <Route
                    exact
                    path="/pages/flag_payrolls/:id"
                    render={() => <FlagPayrollDetailPage />}
                  />
                  <Route
                    exact
                    path="/pages/flag_payrolls/:id/approved"
                    render={() => <FlagApprovedPayrollListPage />}
                  />
                  <Route
                    exact
                    path="/pages/payrolls/edit/:payrollId"
                    render={() => <PayrollDetailEditPage />}
                  />
                  <Route
                    exact
                    path="/pages/additional-payments"
                    render={() => <AdditionalPaymentsPage />}
                  />
                  <Route
                    exact
                    path="/pages/profile"
                    render={() => <ProfilePage />}
                  />
                  {/* <Route path="/pages/global-eor" render={() => <GlobalEor />} /> */}
                  <Route
                    path="/pages/documents"
                    render={() => <DocumentsPage />}
                  />
                  <Route
                    exact
                    path="/pages/cost-calculator"
                    render={() => <CostCalculator />}
                  />
                </Switch>
              </div>
            </div>
            <EditModal
              visible={deactivateVisible}
              footer={false}
              closeModal={() => setDeactivateVisible(false)}
            >
              <div className="d-flex flex-column justify-content-center px-3">
                <div className="d-flex justify-content-center mb-5">
                  <img
                    style={{ width: 'fit-content' }}
                    src="/assets/img/Notification.png"
                    alt="notification"
                  />
                </div>
                <Typography className="heading_semibold__24 text-center">
                  Your company’s account has been deactivated.
                </Typography>
                <Typography className="text_regular-normal__14 text-center mt-2">
                  From now on, you have <b>limited access</b> to the platform:
                  you will not be able to manage your employees and contractors
                  and generate payment for your contractors. Payroll will be no
                  longer generated for your company as well. Meanwhile, you can
                  still sign in to the platform and view your profile,
                  employees’ personal details, uploaded documents and previous
                  invoices. You can also access the chat.
                </Typography>
                <div className="d-flex justify-content-center">
                  <Button
                    data-testid="Router-EABED8"
                    priority="primary"
                    size="small"
                    className="mt-5"
                    onClick={() => setDeactivateVisible(false)}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </EditModal>
          </div>
        </ErrorBoundary>
      </AppContext.Provider>
    </section>
  )
}

export default Pages
