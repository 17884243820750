import {
  EMPLOYEE_PAYMENT_FREQUENCY_MAP,
  EMPLOYEE_STATES,
  PaymentValue,
  PRONOUNS,
} from '@core/constants'
import {
  capitalizeFirstLetter,
  formatDate,
  numberWithSpaces,
} from '@core/utils'
import moment from 'moment'

export const fields = [
  {
    title: 'Name',
    key: 'full_name',
    type: 'string',
  },
  {
    title: 'Role',
    key: 'job_position',
    type: 'string',
  },
]

const getContractType = (employee) =>
  employee.contract_type === 'contractor'
    ? [
        {
          label: 'Contract type',
          value: ['Contractor'],
        },
        {
          label: 'Payment frequency',
          value: [
            PaymentValue[employee.compensation.contractor_payment_frequency],
          ],
        },
        {
          label: 'Estimated working hours',
          value: [employee.compensation.estimated_working_hours],
        },
      ]
    : [
        {
          label: 'Contract type',
          value: ['Employee'],
        },
      ]

const getIdEmployee = (employee) =>
  employee
    ? employee.company.title.slice(0, 3).toUpperCase() +
      employee.profile.first_name[0].toUpperCase() +
      employee.profile.last_name[0].toUpperCase() +
      employee.id
    : null

export const getWorkPermit = (employee) =>
  employee.work_permit
    ? [
        {
          label: 'Work permit status',
          value: [
            employee.work_permit
              ? capitalizeFirstLetter(employee.work_permit.status)
              : '',
          ],
        },
        {
          label: 'Work permit expiry',
          value: [
            employee.work_permit.expiration_date
              ? moment(employee.work_permit.expiration_date).format(
                  'DD MMM YYYY'
                )
              : '-',
          ],
        },
      ]
    : []

export const getResidencyType = (employee) =>
  employee.residency_type
    ? [
        {
          label: 'Residency type',
          value: [
            employee.residency_type === 'resident'
              ? 'Resident'
              : 'Non-Resident',
          ],
        },
      ]
    : []

export const getPersonalInformation = (employee, hide_names_in_invoice) => [
  {
    label: 'Full name',
    value: [
      `${employee.profile.first_name || ''} ${
        employee.profile.middle_name || ''
      } ${employee.profile.last_name || ''}`,
    ],
  },
  {
    label: 'Employee National ID number',
    value: [employee?.profile?.passport_number || 'to be provided'],
  },
  {
    label: 'Pronouns',
    value: [PRONOUNS[employee.profile.pronouns]],
  },
  {
    label: 'Phone number',
    value: [employee?.profile?.phone_number || 'to be provided'],
  },
  {
    label: 'ID',
    value: hide_names_in_invoice ? [getIdEmployee(employee)] : null,
    hint: "Instead of the employee's full name you will see this ID in invoices",
  },
  {
    label: 'Email',
    value: [employee.profile.email],
  },
  {
    label: 'Date of birth',
    value: employee.profile.birth_date
      ? [moment(employee.profile.birth_date).format('DD MMM YYYY')]
      : null,
  },
  {
    label: 'Home Address',
    value: [
      employee.profile.address?.living_country?.name,
      employee.profile.address?.city,
      employee.profile.address?.street_address,
      employee.profile.address?.postal_code,
      employee.profile.address?.address_line,
    ].filter((v) => !!v),
  },
  {
    label: 'Work permit required',
    value: [employee.profile.needs_work_permit ? 'Yes' : 'No'],
  },
  ...getWorkPermit(employee),
  ...getResidencyType(employee),
]
export const getJobDetails = (
  employee,
  hasOccupations = false,
  hasRegions = false
) => [
  {
    label: 'Company',
    value: employee.company.name,
  },
  {
    label: 'Job title',
    value: employee.job.position,
  },
  {
    label: 'Job description',
    value: employee.job.position_description,
  },
  ...(hasOccupations
    ? [
        {
          label: 'Occupation type',
          value: employee?.job?.occupation?.name || '-',
        },
      ]
    : []),
  ...(hasRegions
    ? [
        {
          label: 'Region',
          value: employee?.region?.name || '-',
        },
      ]
    : []),
  {
    label: 'Department',
    value: employee.department,
  },
  ...(employee.contract_type !== 'contractor'
    ? [
        {
          label: 'Pay type',
          value:
            employee?.compensation?.employee_pay_type === 'hourly'
              ? 'Hourly'
              : 'Salaried',
        },
        ...[
          employee?.compensation?.employee_pay_type === 'hourly'
            ? {
                label: 'Base hourly rate',
                value: `${
                  employee.compensation?.currency?.sign ||
                  employee.compensation?.currency?.short_code
                } ${employee?.compensation?.employee_base_hourly_rate}`,
              }
            : [],
        ],
        {
          label: 'Payment frequency',
          value:
            EMPLOYEE_PAYMENT_FREQUENCY_MAP[
              employee?.compensation?.employee_payment_frequency
            ],
        },
      ]
    : []),
  {
    label:
      employee.contract_type === 'contractor'
        ? 'Salary'
        : 'Annual gross salary',
    value:
      employee.contract_type === 'contractor'
        ? `${
            employee.compensation?.currency?.sign ||
            employee.compensation?.currency?.short_code
          }
        ${numberWithSpaces(employee.compensation.contractor_rate)} / ${
            employee.compensation.contractor_wage_type
          }`
        : `${
            employee.compensation?.currency?.sign ||
            employee.compensation?.currency?.short_code
          } ${numberWithSpaces(employee.compensation.yearly_gross_salary)}`,
  },
  {
    label: 'Country number of salaries (per year)',
    value: employee.working_country.yearly_salary_divider,
    hidden: employee.contract_type === 'contractor',
  },
  {
    label: 'Monthly gross salary',
    value: `${
      employee.compensation?.currency?.sign ||
      employee.compensation?.currency?.short_code
    } ${numberWithSpaces(employee.compensation.monthly_salary)}`,
    hidden: employee.contract_type === 'contractor',
  },
  ...getContractType(employee),
  {
    label: 'Contract is permanent',
    value: employee.job.is_permanent ? 'Yes' : 'No',
  },
  ...(employee.contract_type !== 'contractor'
    ? [
        {
          label: 'Employment type',
          value:
            employee?.job?.employment_type === 'part_time'
              ? 'Part-time'
              : 'Full-time',
        },
        {
          label: 'Weekly hours',
          value: `${employee?.compensation?.employee_work_hours_per_week}`,
        },
      ]
    : []),
  {
    label: 'Start date',
    value:
      employee.state === EMPLOYEE_STATES.ACTIVE
        ? formatDate(employee.job.starts_at)
        : formatDate(employee.job.requested_starts_at),
  },
  {
    label: 'End date',
    value: employee.job.ends_at
      ? moment(employee.job.ends_at).format('DD MMM YYYY')
      : undefined,
  },
  {
    label: 'Probation period',
    value: employee.job.probation_period || 'No',
  },
  {
    label: 'Notice period during probation',
    value: employee.job.notice_period_during_probation || 'No',
    hidden: !employee.job.probation_period,
  },
  {
    label: 'Notice period post probation',
    value: employee.job.notice_period_post_probation || 'No',
    hidden: !employee.job.probation_period,
  },
]

const getWorkEquipment = (employee) =>
  employee?.benefit?.provides_work_equipment
    ? [
        {
          label: 'Work equipment specification',
          value: employee?.benefit.equipment_specification,
        },
        {
          label: 'Delivery date',
          value: employee?.benefit.delivered_at
            ? moment(employee.benefit.delivered_at).format('DD MMM YYYY')
            : null,
        },
      ]
    : []
export const getBenefit = (employee) => [
  {
    label: 'Private health insurance',
    value: employee?.benefit?.provides_health_insurance
      ? 'Provided'
      : 'Not provided',
  },
  {
    label: 'Private dental insurance',
    value: employee?.benefit?.provides_dental_insurance
      ? 'Provided'
      : 'Not provided',
  },
  {
    label: 'Work equipment',
    value: employee.benefit.provides_work_equipment
      ? 'Provided'
      : 'Not provided',
  },
  ...getWorkEquipment(employee),
]

export const getEmergencyContact = (contact) => [
  {
    label: 'Contact name',
    value: contact.name,
  },
  {
    label: 'Relationship',
    value: contact.relationship,
  },
  {
    label: 'Email',
    value: contact.email,
  },
  {
    label: 'Phone number',
    value: contact.phone_number,
  },
]

export const getPaidTimeOff = (timeOff) => [
  {
    label: 'Annual paid  leaves by contract',
    value: [timeOff.annual_days_by_contract],
    description:
      "This refers to the total number of paid leave days an employee is eligible for in a year. This figure is typically defined by the employment contract and the company's leave policy, and may vary based on factors like the employee's tenure, role, or regional labour laws.",
  },
  {
    label: 'Accrued paid time off days',
    value: [timeOff.accrued_days],
    description:
      'This term refers to the leave days that an employee earns over a period of time, typically accruing each month. For instance, if the total annual entitlement is 12 days, an employee will accrue 1 day of leave per month. Accrued Days Formula = [Months Worked] * [Annual Entitlement Days/12].',
  },
  {
    label: 'Used paid time off (vacation) days',
    value: [timeOff.used_days],
    description:
      "This is the total number of leave days that an employee has already used. It's subtracted from the annual entitlement (or accrued days) to calculate the remaining leave balance.",
  },
  {
    label: 'Available paid time off (vacation) days',
    value: [timeOff.available_days],
    description:
      'This number represents the remaining paid leave days an employee has at their disposal. It is calculated by taking the total accrued days and subtracting taken days.',
  },
]

export const getPaidSickLeaves = (timeOff) => [
  {
    label: 'Annual paid sick leaves by contract',
    value: [timeOff.annual_days_by_contract],
  },
  {
    label: 'Used paid sick leaves ',
    value: [timeOff.used_days],
  },
  {
    label: 'Available paid sick leaves',
    value: [timeOff.available_days],
  },
]

export const getPaidBereavementLeaves = (timeOff) => [
  {
    label: 'Annual paid bereavement leaves by contract',
    value: [timeOff.annual_days_by_contract],
  },
  {
    label: 'Used paid bereavement leaves ',
    value: [timeOff.used_days],
  },
  {
    label: 'Available paid bereavement leaves',
    value: [timeOff.available_days],
  },
]

export const getPaidParental = (timeOff) => [
  {
    label: 'Annual paid parental leaves by contract',
    value: [timeOff.annual_days_by_contract],
  },
  {
    label: 'Used paid parental leaves',
    value: [timeOff.used_days],
  },
  {
    label: 'Available paid parental leaves',
    value: [timeOff.available_days],
  },
]
