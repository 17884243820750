import { Api } from '@api/Api'

export const customFxRateCreate = async (payload) => {
  const { data } = await Api.post(
    'custom-fx-rate/partner-custom-fx-rate/create/',
    payload
  )

  return data
}
export const getCustomFxRateCreate = async () => {
  const { data } = await Api.get('custom-fx-rate/partner-custom-fx-rate/get/')

  return data
}
