import { createTheme } from '@mui/material/styles'
import React from 'react'

import { cyan, purple } from './colors'
import { hexToRgb } from './theme.utils'

interface ProductPalette {
  primary: React.CSSProperties['color']
  primaryText: React.CSSProperties['color']
  primaryVariant: React.CSSProperties['color']
  primaryVariantText: React.CSSProperties['color']
  secondary: React.CSSProperties['color']
  secondaryText: React.CSSProperties['color']
  secondaryVariant: React.CSSProperties['color']
  secondaryVariantText: React.CSSProperties['color']
  secondaryContainer: React.CSSProperties['color']
  secondaryContainerText: React.CSSProperties['color']
  secondaryContainerVariant: React.CSSProperties['color']
  background1: React.CSSProperties['color']
  background2: React.CSSProperties['color']
  background3: React.CSSProperties['color']
  error: React.CSSProperties['color']
  errorText: React.CSSProperties['color']
  errorContainer: React.CSSProperties['color']
  errorContainerText: React.CSSProperties['color']
  success: React.CSSProperties['color']
  successText: React.CSSProperties['color']
  successContainer: React.CSSProperties['color']
  successContainerText: React.CSSProperties['color']
  warning: React.CSSProperties['color']
  warningText: React.CSSProperties['color']
  warningVariant: React.CSSProperties['color']
  warningVariantText: React.CSSProperties['color']
  warningContainer: React.CSSProperties['color']
  warningContainerText: React.CSSProperties['color']
  surface1: React.CSSProperties['color']
  surface2: React.CSSProperties['color']
  surface3: React.CSSProperties['color']
  outline1: React.CSSProperties['color']
  outline2: React.CSSProperties['color']
}

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary']
    white: string
    black: string
    product: ProductPalette
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions['primary']
    white?: string
    black?: string
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    extraSmall: true
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsSizeOverrides {
    extraSmall: true
  }
}

declare module '@mui/material/styles/createPalette' {
  interface PaletteColor {
    50?: React.CSSProperties['color']
    100?: React.CSSProperties['color']
    200?: React.CSSProperties['color']
    300?: React.CSSProperties['color']
    400?: React.CSSProperties['color']
    500?: React.CSSProperties['color']
    600?: React.CSSProperties['color']
    700?: React.CSSProperties['color']
    800?: React.CSSProperties['color']
    900?: React.CSSProperties['color']
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    displayLarge1: React.CSSProperties
    displayLarge2: React.CSSProperties
    displayMedium1: React.CSSProperties
    displayMedium2: React.CSSProperties
    displaySmall1: React.CSSProperties
    displaySmall2: React.CSSProperties
    h1_strong: React.CSSProperties
    h2_strong: React.CSSProperties
    h3_strong: React.CSSProperties
    textLarge1: React.CSSProperties
    textLarge2: React.CSSProperties
    textLarge3: React.CSSProperties
    body3: React.CSSProperties
    textSmall1: React.CSSProperties
    textSmall2: React.CSSProperties
    textSmall3: React.CSSProperties
    textXSmall1: React.CSSProperties
    textXSmall2: React.CSSProperties
    textXSmall3: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    displayLarge1?: React.CSSProperties
    displayLarge2?: React.CSSProperties
    displayMedium1?: React.CSSProperties
    displayMedium2?: React.CSSProperties
    displaySmall1?: React.CSSProperties
    displaySmall2?: React.CSSProperties
    h1_strong?: React.CSSProperties
    h2_strong?: React.CSSProperties
    h3_strong?: React.CSSProperties
    textLarge1?: React.CSSProperties
    textLarge2?: React.CSSProperties
    textLarge3?: React.CSSProperties
    body3?: React.CSSProperties
    textSmall1?: React.CSSProperties
    textSmall2?: React.CSSProperties
    textSmall3?: React.CSSProperties
    textXSmall1?: React.CSSProperties
    textXSmall2?: React.CSSProperties
    textXSmall3?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    displayLarge1: true
    displayLarge2: true
    displayMedium1: true
    displayMedium2: true
    displaySmall1: true
    displaySmall2: true
    h1_strong: true
    h2_strong: true
    h3_strong: true
    textLarge1: true
    textLarge2: true
    textLarge3: true
    body3: true
    textSmall1: true
    textSmall2: true
    textSmall3: true
    textXSmall1: true
    textXSmall2: true
    textXSmall3: true
    h4: false
    h5: false
    h6: false
    subtitle1: false
    subtitle2: false
  }
}

declare module '@mui/material/styles/createTypography' {
  interface FontStyle {
    fontWeightSemiBold: React.CSSProperties['fontWeight']
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false
    sm: false
    md: false
    lg: false
    xl: true
    mobile: true
    tablet: true
    laptop: true
    desktop: true
  }
}

const fontFamilyInter = [
  'Inter',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',')

const fontFamilyLexend = [
  'Lexend',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',')

const theme = createTheme({
  palette: {
    primary: {
      main: '#40b84c',
      '300': '#137547',
      '500': '#42DE6E',
      '600': '#71FA97',
      '700': '#C3FFD4',
      '800': '#E2FFEA',
      '900': '#F6FFF8',
    },
    secondary: {
      main: '#141414',
      '50': '#1F1F1F',
      '100': '#3D3D3D',
      '200': '#5C5C5C',
      '300': '#6D6D6C',
      '400': '#8F8F8F',
      '500': '#ADADAD',
      '600': '#CCCCCC',
      '700': '#E0E0E0',
      '800': '#F0F0F0',
      '900': '#F5F5F5',
    },
    tertiary: {
      main: '#007994',
      '50': '#00262E',
      '100': '#003A47',
      '200': '#00647A',
      '400': '#008EAD',
      '500': '#4CB0C7',
      '600': '#7CCCDE',
      '700': '#BBE6F0',
      '800': '#DEF7FC',
      '900': '#F5FDFF',
    },
    error: {
      main: '#D6331F',
      '50': '#530A00',
      '100': '#851203',
      '200': '#B21F0D',
      '400': '#E94E3A',
      '500': '#F77160',
      '600': '#FF9B8F',
      '700': '#FFCCC5',
      '800': '#FFE2DE',
      '900': '#FFF9F8',
    },
    success: {
      main: '#2DB77B',
      '50': '#0B442B',
      '100': '#137149',
      '200': '#1E9863',
      '400': '#47D094',
      '500': '#68E3AD',
      '600': '#92F0C7',
      '700': '#C2F9E1',
      '800': '#DBFCEE',
      '900': '#F6FFFB',
    },
    warning: {
      main: '#FFA530',
      '50': '#462900',
      '100': '#774400',
      '200': '#A25E03',
      '300': '#C8760B',
      '400': '#E68E19',
      '600': '#FFBC5B',
      '700': '#FFD28D',
      '800': '#FFE8C2',
      '900': '#FFFBF5',
    },
    white: '#fff',
    black: '#121212',
  },
  typography: {
    fontFamily: fontFamilyInter,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    displayLarge1: {
      fontFamily: fontFamilyLexend,
      fontWeight: 300,
      fontSize: '56px',
      // lineHeight: '68px',
      lineHeight: 1.2,
    },
    displayLarge2: {
      fontFamily: fontFamilyLexend,
      fontWeight: 400,
      fontSize: '56px',
      // lineHeight: '68px',
      lineHeight: 1.2,
    },
    displayMedium1: {
      fontFamily: fontFamilyLexend,
      fontWeight: 300,
      fontSize: '48px',
      // lineHeight: '56px',
      lineHeight: 1.2,
    },
    displayMedium2: {
      fontFamily: fontFamilyLexend,
      fontWeight: 400,
      fontSize: '48px',
      // lineHeight: '56px',
      lineHeight: 1.2,
    },
    displaySmall1: {
      fontFamily: fontFamilyLexend,
      fontWeight: 300,
      fontSize: '40px',
      // lineHeight: '48px',
      lineHeight: 1.2,
    },
    displaySmall2: {
      fontFamily: fontFamilyLexend,
      fontWeight: 400,
      fontSize: '40px',
      // lineHeight: '48px',
      lineHeight: 1.2,
    },
    h1: {
      fontFamily: fontFamilyLexend,
      fontWeight: 300,
      fontSize: '32px',
      // lineHeight: '36px',
      lineHeight: 1.2,
    },
    h1_strong: {
      fontFamily: fontFamilyLexend,
      fontWeight: 400,
      fontSize: '32px',
      // lineHeight: '36px',
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: fontFamilyLexend,
      fontWeight: 300,
      fontSize: '24px',
      // lineHeight: '28px',
      lineHeight: 1.2,
    },
    h2_strong: {
      fontFamily: fontFamilyLexend,
      fontWeight: 400,
      fontSize: '24px',
      // lineHeight: '28px',
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: fontFamilyLexend,
      fontWeight: 300,
      fontSize: '20px',
      // lineHeight: '24px',
      lineHeight: 1.2,
    },
    h3_strong: {
      fontFamily: fontFamilyLexend,
      fontWeight: 400,
      fontSize: '20px',
      // lineHeight: '24px',
      lineHeight: 1.2,
    },
    body1: {
      fontFamily: fontFamilyInter,
      fontWeight: 400,
      fontSize: '14px',
      // lineHeight: '18px',
      lineHeight: 1.2,
    },
    body2: {
      fontFamily: fontFamilyInter,
      fontWeight: 500,
      fontSize: '14px',
      // lineHeight: '18px',
      lineHeight: 1.2,
    },
    body3: {
      fontFamily: fontFamilyInter,
      fontWeight: 600,
      fontSize: '14px',
      // lineHeight: '18px',
      lineHeight: 1.2,
    },
    textLarge1: {
      fontFamily: fontFamilyInter,
      fontWeight: 400,
      fontSize: '16px',
      // lineHeight: '20px',
      lineHeight: 1.2,
    },
    textLarge2: {
      fontFamily: fontFamilyInter,
      fontWeight: 500,
      fontSize: '16px',
      // lineHeight: '20px',
      lineHeight: 1.2,
    },
    textLarge3: {
      fontFamily: fontFamilyInter,
      fontWeight: 600,
      fontSize: '16px',
      // lineHeight: '20px',
      lineHeight: 1.2,
    },
    textSmall1: {
      fontFamily: fontFamilyInter,
      fontWeight: 400,
      fontSize: '12px',
      // lineHeight: '16px',
      lineHeight: 1.2,
    },
    textSmall2: {
      fontFamily: fontFamilyInter,
      fontWeight: 500,
      fontSize: '12px',
      // lineHeight: '16px',
      lineHeight: 1.2,
    },
    textSmall3: {
      fontFamily: fontFamilyInter,
      fontWeight: 600,
      fontSize: '12px',
      // lineHeight: '16px',
      lineHeight: 1.2,
    },
    textXSmall1: {
      fontFamily: fontFamilyInter,
      fontWeight: 400,
      fontSize: '10px',
      // lineHeight: '14px',
      lineHeight: 1.2,
    },
    textXSmall2: {
      fontFamily: fontFamilyInter,
      fontWeight: 500,
      fontSize: '10px',
      // lineHeight: '14px',
      lineHeight: 1.2,
    },
    textXSmall3: {
      fontFamily: fontFamilyInter,
      fontWeight: 600,
      fontSize: '10px',
      // lineHeight: '14px',
      lineHeight: 1.2,
    },
    button: {
      fontFamily: fontFamilyInter,
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 1,
    },
  },
  spacing: 8,
  breakpoints: {
    values: {
      xl: 560,
      mobile: 768,
      tablet: 1280,
      laptop: 1440,
      desktop: 1920,
    },
  },
})

console.log(theme)

theme.palette = {
  ...theme.palette,
  product: {
    primary: theme.palette.primary.main,
    primaryText: theme.palette.white,
    primaryVariant: theme.palette.primary[300],
    primaryVariantText: theme.palette.white,
    secondary: theme.palette.secondary.main,
    secondaryText: theme.palette.white,
    secondaryVariant: theme.palette.secondary[400],
    secondaryVariantText: theme.palette.secondary[300],
    secondaryContainer: theme.palette.secondary[800],
    secondaryContainerText: theme.palette.secondary[500],
    secondaryContainerVariant: theme.palette.white,
    error: theme.palette.error.main,
    errorText: theme.palette.white,
    errorContainer: theme.palette.error[800],
    errorContainerText: theme.palette.error[50],
    success: theme.palette.success.main,
    successText: theme.palette.white,
    successContainer: theme.palette.success[800],
    successContainerText: theme.palette.success[50],
    warning: theme.palette.warning.main,
    warningText: theme.palette.white,
    warningVariant: theme.palette.warning[50],
    warningVariantText: theme.palette.white,
    warningContainer: theme.palette.warning[800],
    warningContainerText: theme.palette.warning[50],
    background1: theme.palette.white,
    background2: theme.palette.secondary[900],
    background3: theme.palette.secondary[800],
    surface1: `rgb(${hexToRgb(theme.palette.secondary[50])} / 4%)`,
    surface2: `rgb(${hexToRgb(theme.palette.secondary[50])} / 8%)`,
    surface3: `rgb(${hexToRgb(theme.palette.secondary[50])} / 12%)`,
    outline1: `rgb(${hexToRgb(theme.palette.secondary[50])} / 8%)`,
    outline2: `rgb(${hexToRgb(theme.palette.secondary[50])} / 12%)`,
  },
}

theme.components = {
  MuiCssBaseline: {
    styleOverrides: ({ palette }) => ({
      body: {
        color: palette.product.secondary,
      },
      a: {
        color: palette.product.primary,
        textDecoration: 'none',
      },
      svg: {
        fill: 'currentColor',
      },
    }),
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h1_strong: 'h1',
        h2_strong: 'h2',
        h3_strong: 'h3',
        displayLarge1: 'h1',
        displayLarge2: 'h1',
        displayMedium1: 'h2',
        displayMedium2: 'h2',
        displaySmall1: 'h3',
        displaySmall2: 'h3',
        textLarge1: 'div',
        textLarge2: 'div',
        textLarge3: 'div',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: '4px',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.product.secondaryContainerText,
          border: 'none',
          color: theme.palette.white,
        },
      },
      sizeMedium: {
        fontSize: '16px',
        height: '40px',
        padding: theme.spacing(1.25, 2),
      },
      sizeSmall: {
        fontSize: '16px',
        height: '32px',
        padding: theme.spacing(0.75, 1.5),
      },
      containedPrimary: {
        backgroundColor: theme.palette.product.primary,
        color: theme.palette.product.primaryText,
        '&:hover': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.product.primary}`,
        },
      },
      containedSecondary: {
        backgroundColor: theme.palette.white,
        border: `1px solid ${theme.palette.product.surface3}`,
        color: theme.palette.black,
        '&:hover': {
          backgroundColor: theme.palette.product.secondaryContainerVariant,
        },
        '&:active': {
          backgroundColor: theme.palette.product.secondaryContainer,
        },
        '&:not(.custom-svg) svg path': {
          fill: theme.palette.product.secondaryVariant,
        },
        '&.Mui-disabled svg path': {
          fill: theme.palette.white,
        },
      },
      containedError: {
        '&:hover': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.product.error}`,
        },
      },
      textSecondary: {
        '&:hover': {
          background: 'none',
        },
        '&:active': {
          backgroundColor: cyan[900],
        },
        '& svg path': {
          fill: theme.palette.product.secondaryVariant,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.white,
          border: 'none',
          color: 'inherit',
        },
      },
      iconSizeMedium: {
        width: '20px',
        height: '20px',
        svg: {
          width: '20px',
          height: '20px',
        },
      },
      iconSizeSmall: {
        width: '20px',
        height: '20px',
        svg: {
          width: '20px',
          height: '20px',
        },
      },
      startIcon: {},
    },
    variants: [
      {
        props: { size: 'extraSmall' },
        style: {
          fontSize: '14px',
          height: '24px',
          padding: theme.spacing(0.5, 1),
          '.MuiButton-iconSizeExtraSmall': {
            width: '16px',
            height: '16px',
            svg: {
              width: '16px',
              height: '16px',
            },
          },
          '.MuiButton-startIcon': {
            marginLeft: 0,
            marginRight: theme.spacing(0.5),
          },
          '.MuiButton-endIcon': {
            marginLeft: theme.spacing(0.5),
            marginRight: 0,
          },
        },
      },
    ],
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        borderRadius: '4px',
        '&.Mui-disabled': {
          backgroundColor: theme.palette.product.secondaryContainerText,
          border: 'none',
          color: theme.palette.white,
        },
      },
      colorPrimary: {
        backgroundColor: theme.palette.product.primary,
        '&:hover': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.product.primary}`,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.product.background3,
        },
        '& svg path': {
          fill: theme.palette.white,
        },
      },
      colorSecondary: {
        backgroundColor: theme.palette.white,
        border: `1px solid ${theme.palette.product.surface3}`,
        color: theme.palette.black,
        '&:hover': {
          backgroundColor: theme.palette.product.background2,
        },
        '&:active': {
          backgroundColor: theme.palette.product.background3,
        },
        '& svg path': {
          fill: theme.palette.product.secondaryVariantText,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.white,
          border: `1px solid ${theme.palette.product.background3}`,
        },
        '&.Mui-disabled svg path': {
          fill: theme.palette.product.background3,
        },
      },
      sizeMedium: {
        width: '40px',
        height: '40px',
        padding: theme.spacing(1),

        svg: {
          width: '24px',
          height: '24px',
        },
      },
      sizeSmall: {
        width: '32px',
        height: '32px',
        padding: theme.spacing(0.5),

        svg: {
          width: '20px',
          height: '20px',
        },
      },
    },
    variants: [
      {
        props: { size: 'extraSmall' },
        style: {
          width: '24px',
          height: '24px',
          padding: theme.spacing(0.5),
          svg: {
            width: '16px',
            height: '16px',
          },
        },
      },
    ],
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        minWidth: '280px',
        'svg path': {
          fill: '#6D7C8B',
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.product.background3,
        },
        [theme.breakpoints.down('tablet')]: {
          minWidth: 'auto',
        },
      },
      input: {
        fontSize: '16px',
        lineHeight: '20px',
        height: '40px',
        boxSizing: 'border-box',
        padding: 0,
        '&::placeholder': {
          color: theme.palette.secondary[400],
          opacity: 1,
        },
      },
      inputSizeSmall: {
        height: '32px',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        // backgroundColor: 'purple',
        color: theme.palette.black,
        '&:hover': {
          '&:not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline':
            {
              borderColor: theme.palette.product.primary,
            },
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.product.background3,
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.product.outline2,
        },
        // removes empty space
        '& legend': {
          width: 0,
        },
      },
      input: {
        padding: theme.spacing(1.25, 1),
        '&.Mui-disabled': {
          color: theme.palette.black,
          WebkitTextFillColor: 'currentColor',
        },
      },
      multiline: {
        padding: 0,
      },
      notchedOutline: {
        borderColor: theme.palette.product.secondaryContainerText,
        top: 0,
        '& legend': {
          display: 'none',
        },
      },
      adornedStart: {
        paddingLeft: theme.spacing(1),
      },
      adornedEnd: {
        paddingRight: theme.spacing(1),
      },
      inputSizeSmall: {
        padding: theme.spacing(0.75, 1),
      },
      error: {
        borderColor: theme.palette.product.error,
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      positionStart: {
        marginRight: 0,
      },
      positionEnd: {
        marginLeft: 0,
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      shrink: true,
    },
    styleOverrides: {
      root: {
        position: 'static',
        transform: 'none',
        color: theme.palette.product.secondaryVariantText,
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 500,
        marginBottom: theme.spacing(0.5),
        '&.Mui-disabled': {
          color: theme.palette.product.secondaryVariantText,
        },
      },
      asterisk: {
        color: theme.palette.product.error,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginTop: theme.spacing(0.5),
        color: theme.palette.product.secondaryContainerText,
        '&.Mui-disabled': {
          color: theme.palette.product.secondaryContainerText,
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '&.DatePicker__TextField .MuiOutlinedInput-root': {
          padding: 0,
        },
        '&.DatePicker__TextField .MuiOutlinedInput-root .MuiIconButton-root': {
          margin: 0,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1.25, 2),
        '&&&': {
          minHeight: '40px',
        },
        '& .MuiListItemIcon-root': {
          minWidth: 'auto',
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '& .MuiInputBase-root': {
          padding: 0,
        },
        '& .MuiIconButton-root': {
          width: 'auto',
          height: 'auto',

          '& svg': {
            width: '1em',
            height: '1em',
          },
        },
      },
      endAdornment: {},
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        color: '#D6331F',
      },
      root: {
        marginBottom: theme.spacing(0.5),
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: '-7px',
        marginRight: theme.spacing(2),
      },
      labelPlacementStart: {
        marginLeft: theme.spacing(2),
        marginRight: '-7px',
      },
      labelPlacementBottom: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
      labelPlacementTop: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: theme.palette.product.secondaryVariantText,
        padding: '4px',
        '&.Mui-disabled': {
          color: theme.palette.product.secondaryContainerText,
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: theme.palette.product.secondaryVariantText,
        padding: '4px',
        '&.Mui-disabled': {
          color: theme.palette.product.secondaryContainerText,
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        width: 39,
        height: 25,
        padding: 0,
        '&:hover .Mui-checked:not(.Mui-disabled) + .MuiSwitch-track': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${theme.palette.product.primary}`,
        },
        '&&': {
          margin: theme.spacing(1),
        },
      },
      switchBase: {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(14px)',
          color: theme.palette.white,
          '& + .MuiSwitch-track': {
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: theme.palette.product.primary,
          border: `6px solid ${theme.palette.white}`,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.white,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      thumb: {
        boxSizing: 'border-box',
        width: 21,
        height: 21,
      },
      track: {
        borderRadius: 25 / 2,
        backgroundColor: theme.palette.product.secondaryVariant,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 500,
        }),
      },
      sizeSmall: {
        height: 20,

        '& .MuiSwitch-switchBase': {
          padding: 0,
          '&.Mui-checked': {
            transform: 'translateX(19px)',
          },
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${theme.palette.product.outline2}`,
        minHeight: 'auto',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        color: theme.palette.product.secondaryVariantText,
        fontSize: '14px',
        lineHeight: '18px',
        padding: theme.spacing(1.25, 2),
        textTransform: 'initial',
        minHeight: 'auto',
        '&.Mui-selected': {
          color: 'inherit',
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: purple[500],
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 4,
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        backgroundColor: theme.palette.product.secondaryContainer,
        color: theme.palette.product.secondaryVariantText,
        '& .MuiChip-deleteIcon:hover': {
          color: 'inherit',
        },
      },
      iconMedium: {
        width: 16,
        height: 16,
        color: 'currentColor',
      },
      iconSmall: {
        color: 'currentColor',
        width: 16,
        height: 16,
      },
      deleteIcon: {
        color: 'inherit',
      },
      deleteIconMedium: {
        width: 16,
        height: 16,
      },
      deleteIconSmall: {
        width: 16,
        height: 16,
      },
      colorSuccess: {
        backgroundColor: theme.palette.product.successContainer,
        color: theme.palette.product.success,
        '&.MuiChip-clickable:hover': {
          backgroundColor: theme.palette.product.success,
          color: theme.palette.product.successText,
        },
      },
      colorInfo: {
        backgroundColor: cyan[900],
        color: cyan[500],
        '&.MuiChip-clickable:hover': {
          backgroundColor: cyan[800],
          color: cyan[400],
        },
      },
      colorWarning: {
        backgroundColor: theme.palette.product.warningContainer,
        color: theme.palette.product.warning,
        '&.MuiChip-clickable:hover': {
          backgroundColor: theme.palette.product.warning,
          color: theme.palette.product.warningText,
        },
      },
      colorError: {
        backgroundColor: theme.palette.product.errorContainer,
        color: theme.palette.product.error,
        '&.MuiChip-clickable:hover': {
          backgroundColor: theme.palette.product.error,
          color: theme.palette.product.errorText,
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        height: '64px',
        alignItems: 'center',
        borderRadius: '6px',
      },
      message: {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
      },

      action: {
        padding: 0,
      },
      standardError: {
        backgroundColor: theme.palette.product.errorContainer,
        border: `1px solid ${theme.palette.product.error}`,
      },
      standardWarning: {
        backgroundColor: theme.palette.product.warningContainer,
        border: `1px solid ${theme.palette.product.warning}`,
      },
      standardInfo: {
        backgroundColor: theme.palette.info[800],
        border: `1px solid ${theme.palette.info.main}`,
      },
      standardSuccess: {
        backgroundColor: theme.palette.product.successContainer,
        border: `1px solid ${theme.palette.product.success}`,
      },
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        margin: 0,
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        marginRight: theme.spacing(2),
      },
    },
  },
  MuiStep: {
    styleOverrides: {
      root: {
        marginBottom: theme.spacing(3),
      },
    },
  },
  MuiStepContent: {
    styleOverrides: {
      root: {
        border: 'none',
        marginLeft: '62px',
        marginTop: theme.spacing(1.25),
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
      label: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px',
      },
      labelContainer: {
        color: theme.palette.secondary[400],
        display: 'flex',
        flexDirection: 'column-reverse',
      },
      iconContainer: {
        paddingRight: 0,
        marginRight: theme.spacing(1.25),
        paddingLeft: theme.spacing(3),
        borderLeft: '8px solid transparent',
        height: '50px',
        alignItems: 'center',

        '&.Mui-active': {
          borderColor: theme.palette.product.primary,
        },
      },
      active: {
        background: 'red',
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        display: 'none',
      },
      line: {
        border: 'none',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        minWidth: 'min(100vw, 500px)',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${theme.palette.secondary[800]}`,
        '&&': {
          padding: theme.spacing(2, 3),
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        '&&': {
          padding: theme.spacing(2, 3),
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        '&&': {
          padding: theme.spacing(2, 3),
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {},
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1, 1.5),
        borderRadius: '4px',
        borderLeft: `2px solid transparent`,
        '&:hover, &:focus': {
          backgroundColor: theme.palette.product.background3,
          '& .MuiListItemIcon-root, & .MuiListItemText-root .MuiTypography-root':
            {
              color: theme.palette.product.primaryVariant,
            },
        },
        '&.active': {
          backgroundColor: theme.palette.white,
          boxShadow: '0px 1px 2px rgb(16 24 40 / 5%)',
          borderColor: theme.palette.product.primaryVariant,
          '& .MuiListItemIcon-root, & .MuiListItemText-root .MuiTypography-root':
            {
              color: theme.palette.product.primaryVariant,
            },
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 'auto',
        marginRight: theme.spacing(1),
        svg: {
          width: '20px',
          height: '20px',
        },
      },
    },
  },
  MuiListItemText: {
    defaultProps: {
      primaryTypographyProps: {
        variant: 'body2',
        color: theme.palette.product.secondaryVariant,
      },
    },
    styleOverrides: {
      root: {
        margin: 0,
      },
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      root: {
        background: 'none',
        padding: theme.spacing(0.75, 1.5),
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        background: theme.palette.product.background2,
        padding: 0,
        height: '40px',
      },
      body: { height: '48px' },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: theme.palette.product.background2,
          cursor: 'pointer',
        },
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        border: 'solid 1px',
        borderColor: theme.palette.grey[300],
        borderRadius: 4,
        '& th, td': {
          padding: theme.spacing(0, 3),
        },
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        // These changes affect components where MuiSvgIcon is also used (i.e. Checkbox, Radio ...)
        // width: '20px',
        // height: '20px',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      popper: {
        filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12))',
      },
      tooltip: {
        backgroundColor: theme.palette.white,
        color: theme.palette.black,
        padding: theme.spacing(1),
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
      },
      arrow: {
        color: theme.palette.white,
      },
    },
  },
  // TODO JAFFAR: Ask if we will ever have underlined ones
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none',
      },
    },
  },
}

// theme = responsiveFontSizes(theme);

export default theme
