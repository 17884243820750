import './multi-currency-status.scss'

import { capitalizeFirstLetter } from '@core/utils'
import React from 'react'

export function MultiCurrencyMainStatus({ status }) {
  if (!status) return <div className="status_multi_currency">No Status</div>
  switch (status.toLowerCase()) {
    case 'to_approve':
      return (
        <div className="status_multi_currency status_to_approve_multi_currency_main">
          To approve
        </div>
      )
    case 'partially_approved':
      return (
        <div className="status_multi_currency status_partially_approved_multi_currency_main">
          Partially Approved
        </div>
      )
    case 'approved':
      return (
        <div className="status_multi_currency status_approved_multi_currency_main">
          Approved
        </div>
      )
    default:
      return (
        <div className="status_multi_currency">{status.replace('_', ' ')}</div>
      )
  }
}

export function MultiCurrencyStatus({ status }) {
  if (!status) return <div className="status_multi_currency">No Status</div>
  switch (status.toLowerCase()) {
    case 'created':
      return (
        <div className="status_multi_currency status_to_approve_multi_currency">
          To approve
        </div>
      )
    case 'need_changes_from_partner':
      return (
        <div className="status_multi_currency status_changes_requested_multi_currency">
          Change requested
        </div>
      )
    case 'approved_by_admin':
    case 'approved_by_partner':
    case 'partner_invoice_generated':
    case 'client_invoice_generated':
    case 'need_changes_from_admin':
      return (
        <div className="status_multi_currency status_approved_multi_currency">
          Approved
        </div>
      )
    default:
      return (
        <div className="status_multi_currency">
          {capitalizeFirstLetter(status).replace('_', ' ')}
        </div>
      )
  }
}
