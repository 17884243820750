import Typography from '@atoms/Typography/Typography'
import {
  capitalizeFirstLetter,
  mapEnum,
  numberWithSpaces,
  SplitNumber,
} from '@core/utils'
import { getPayrollById } from '@services/payroll.service'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

const Styled = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
`

const StyledTable = styled.table`
  tr,
  th {
    height: 32px;
    color: unset;
    border-radius: 4px;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`

const AdditionalPaymentsTrs = ({ payrollId }) => {
  const { data, isFetching } = useQuery('additionalPaymentPayroll', {
    queryFn: () => getPayrollById(payrollId),
    enabled: !!payrollId,
  })

  if (isFetching) {
    return (
      <tr>
        <th>
          <div className="ml-3 mt-2">
            <DotWave size={30} />
          </div>
        </th>
      </tr>
    )
  }

  return data.additional_payments.map(
    ({ id, name, ap_type, amount_in_partner_currency }) => {
      return (
        <tr key={id}>
          <th>
            <Typography
              className="text_regular-normal__14 ml-2"
              style={{ letterSpacing: '0.4px' }}
            >
              {`${name} (${mapEnum(capitalizeFirstLetter(ap_type))}) :`}
            </Typography>
          </th>
          <td align="right">
            <Typography
              className="text_regular-normal__14 mr-2"
              style={{ letterSpacing: '0.4px' }}
            >
              {SplitNumber(amount_in_partner_currency || 0)}
            </Typography>
          </td>
        </tr>
      )
    }
  )
}

export default ({ payroll, invoiceTotal }) => {
  return (
    <Styled>
      <div className="d-flex align-items-center">
        <Typography className="heading_semibold__18">
          Payroll #{payroll.id}
        </Typography>

        {payroll?.month && (
          <Typography className="ml-2 heading_semibold__16">
            {moment(payroll.month).format('MMMM, YYYY')}
          </Typography>
        )}
      </div>
      <div style={{ border: '1px dashed #DFDFDF', margin: '12px 0' }} />
      <div className="d-flex justify-content-between mt-2">
        <Typography
          className="text_light__12 mb-3 color_text_300 text-uppercase"
          style={{ letterSpacing: '0.4px' }}
        >
          Type
        </Typography>
        <Typography
          className="text_light__12 mb-3 color_text_300 mr-2"
          style={{ letterSpacing: '0.4px' }}
        >
          {payroll?.partner_currency?.short_code}
        </Typography>
      </div>
      <StyledTable>
        <tr>
          <th>
            <Typography
              className="text_regular-normal__14 ml-2"
              style={{ letterSpacing: '0.4px' }}
            >
              Gross pay:
            </Typography>
          </th>
          <td align="right">
            <Typography
              className="text_regular-normal__14 mr-2"
              style={{ letterSpacing: '0.4px' }}
            >
              {numberWithSpaces(payroll.gross_pay_in_partner_currency)}
            </Typography>
          </td>
        </tr>
        <tr>
          <th>
            <Typography
              className="text_regular-normal__14 ml-2"
              style={{ letterSpacing: '0.4px' }}
            >
              Employer tax & contributions:
            </Typography>
          </th>
          <td align="right">
            <Typography
              className="text_regular-normal__14 mr-2"
              style={{ letterSpacing: '0.4px' }}
            >
              {numberWithSpaces(payroll.employer_burden_in_partner_currency)}
            </Typography>
          </td>
        </tr>
        <tr>
          <th>
            <Typography
              className="text_regular-normal__14 ml-2"
              style={{ letterSpacing: '0.4px' }}
            >
              Private Insurance:
            </Typography>
          </th>
          <td align="right">
            <Typography
              className="text_regular-normal__14 mr-2"
              style={{ letterSpacing: '0.4px' }}
            >
              {numberWithSpaces(payroll.private_insurance_in_partner_currency)}
            </Typography>
          </td>
        </tr>
        <tr>
          <th>
            <Typography
              className="text_regular-normal__14 ml-2"
              style={{ letterSpacing: '0.4px' }}
            >
              Partner fee:
            </Typography>
          </th>
          <td align="right">
            <Typography
              className="text_regular-normal__14 mr-2"
              style={{ letterSpacing: '0.4px' }}
            >
              {numberWithSpaces(payroll.partner_fee_in_partner_currency)}
            </Typography>
          </td>
        </tr>
        <tr>
          <th>
            <Typography
              className="text_regular-normal__14 ml-2"
              style={{ letterSpacing: '0.4px' }}
            >
              VAT:
            </Typography>
          </th>
          <td align="right">
            <Typography
              className="text_regular-normal__14 mr-2"
              style={{ letterSpacing: '0.4px' }}
            >
              {numberWithSpaces(payroll.vat_in_partner_currency)}
            </Typography>
          </td>
        </tr>
        <AdditionalPaymentsTrs payrollId={payroll.id} />
      </StyledTable>
      <div style={{ border: '1px dashed #DFDFDF', margin: '12px 0' }} />
      {payroll.absences.length > 0 && (
        <div className="d-flex justify-content-between">
          <Typography
            className="text_regular-normal__14 color_text_300"
            style={{ letterSpacing: '0.4px' }}
          >
            Time-off:
          </Typography>
          <div className="d-flex flex-column mb-2">
            {payroll.absences.map((absence) => (
              <div
                className="text_medium__14"
                style={{ letterSpacing: '0.4px' }}
              >
                {absence.is_paid ? 'Paid' : 'Unpaid'} Time-off from{' '}
                {moment(absence.start_date).format('MMM Do')} till{' '}
                {moment(absence.end_date).format('MMM Do')}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="d-flex justify-content-between">
        <Typography
          className="text_regular-normal__14 color_text_300"
          style={{ letterSpacing: '0.4px' }}
        >
          Total:
        </Typography>
        <b className="text_medium__14" style={{ letterSpacing: '0.4px' }}>
          {payroll?.partner_currency?.sign ||
            payroll?.partner_currency?.short_code}{' '}
          {numberWithSpaces(payroll?.total_amount_in_partner_currency)}
        </b>
      </div>
    </Styled>
  )
}
