import { numberWithSpaces } from '@core/utils'
import { mapCurrency } from '@remoteam-front/utils/currency'
import React from 'react'

import PayrollDetails from './PayrollDetails'

export const parserPayrollsBasePayment = (payrolls) => {
  return payrolls.map((payroll) => ({
    id: payroll.id,
    gross_salary: `${mapCurrency(payroll?.partner_currency)} ${numberWithSpaces(
      payroll?.total_amount_in_partner_currency
    )}`,
    name: payroll.user.full_name,
    avatar: payroll.user.avatar,
    status: payroll.state,
    ...payroll,
    country: payroll?.country?.name,
    disabled:
      payroll.state !== 'created'
        ? payroll.state !== 'need_changes_from_partner'
        : false,
    collapseContent: <PayrollDetails payroll={payroll} />,
  }))
}
