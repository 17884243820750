import './ProfilePage.scss'

import { Api } from '@api/Api'
import Avatar from '@atoms/Avatar/Avatar'
import Button from '@atoms/Button/Button'
import EditAvatar from '@atoms/EditAvatar/EditAvatar'
import EditModal from '@atoms/EditModal/EditModal'
import Input from '@atoms/Input/Input'
import List from '@atoms/List/List'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Select from '@atoms/Select/Select'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import {
  eorFormId,
  EorSettings,
} from '@components/eor-settings/eor-settings.component'
import { AppContext } from '@core/context'
import { useToast } from '@core/hooks/useErrorNotification'
import { Box } from '@mui/material'
import { NoticePeriod } from '@pages/overview/onboarding-checklist/country-guide/notice-period/notice-period.component'
import { SecurityTab } from '@pages/profile/security-2fa/security-2fa-tab'
import {
  eorSettingsUpdate,
  getProbationPeriod,
  getWorkSchedule,
  invoiceFieldsUpdate,
} from '@remoteam-front/services'
import { AsyncButton } from '@remoteam-front/ui'
import { getResetPassword } from '@services/app.service'
import { patchProfile } from '@services/profile.service'
import { DotWave } from '@uiball/loaders'
import { useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import { BillingDetails } from './billing-details/billing-details.component'
import { ComplianceTab } from './compliance-tab/compliance-tab'
import { ManagersTab } from './managers-tab/managers-tab'
import { getCompanyDetails } from './mock'
import { ProbationPeriodTab } from './probation-period-tab/probation-period-tab.component'
import { SalaryTab } from './salary-tab/salary-tab.component'
import { WorkScheduleTab } from './work-schedule-tab/work-schedule-tab.component'

const tabs = {
  managers: 'managers',
  compliance: 'compliance',
  billing_address: 'billing_address',
  settings: 'settings',
  eor_settings: 'eor_settings',
  country_guides: 'country_guides',
  password_and_account: 'password_and_account',
  security: 'security',
}

export default function ProfilePage() {
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const activeTab = queryParams.get('tab') || tabs.managers

  const {
    profile,
    refetchPartnerProfile,
    countries,
    userProfile,
    refetchProfile,
  } = useContext(AppContext)
  const { successAlert } = useToast()
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [avatarImage, setAvatarImage] = useState()
  const { toggle: clickReset, value: resetClick } = useBoolean(false)
  const [teams, setTeams] = useState([])
  const [editState, setEditState] = useState({
    company_name: '',
    living_country: '',
    street_address: '',
    state: '',
    city: '',
    postal_code: '',
  })
  const { mutate: fetchUpdateProfile, isLoading: updateLoading } = useMutation({
    mutationFn: (payload) => patchProfile(payload),
    onSuccess: () => {
      refetchPartnerProfile()
      setVisibleEdit(false)
      successAlert('Successfully updated')
    },
  })

  const resetPassword = useMutation({
    mutationFn: (payload) => getResetPassword(payload.email),
    onSuccess: () => {
      successAlert('Check your email for a link to set your password.')
    },
  })

  const probationPeriodQuery = useQuery([getProbationPeriod.key], {
    enabled: !!profile?.allowed_countries.length,
    queryFn: () => getProbationPeriod.fetch(profile?.allowed_countries[0]?.id),
  })
  const workScheduleQuery = useQuery([getWorkSchedule.key], {
    enabled: !!profile?.allowed_countries.length,
    queryFn: () => getWorkSchedule.fetch(profile?.allowed_countries[0]?.id),
  })

  const mutationUploadAvatar = async (file) => {
    const fd = new FormData()
    fd.append('file', file)

    return Api.post('/profile/avatar/', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  const uploadProfileAvatar = useMutation(mutationUploadAvatar, {
    onSuccess: (_, imageFile) => {
      refetchProfile()
      setAvatarImage(URL.createObjectURL(imageFile))
      successAlert('Your profile image has been updated successfully')
    },
  })

  const onAvatarUpload = (file) => uploadProfileAvatar.mutate(file)

  const eorSettingsUpdateMutation = useMutation({
    mutationFn: (body) => eorSettingsUpdate(profile.partner_profile_id, body),
  })

  const invoiceFieldsUpdateMutation = useMutation({
    mutationFn: (body) => invoiceFieldsUpdate(profile.partner_profile_id, body),
  })

  const handleEorSettingsSubmit = ({ eorSettings, invoiceFields }) => {
    eorSettingsUpdateMutation.mutateAsync(eorSettings).then(() => {
      const { payroll_frequencies } = invoiceFields
      invoiceFieldsUpdateMutation.mutate({ payroll_frequencies })
    })
  }

  const onChangeEdit = (evt) => {
    const { value } = evt.target
    setEditState((prevState) => ({
      ...prevState,
      [evt.target.name]: value,
    }))
  }
  const onChangeLivingCountry = (option) => {
    setEditState((prevState) => ({
      ...prevState,
      living_country: option.value,
    }))
  }
  const onSaveEdit = () => {
    const editData = {
      name: editState.company_name,
      legal_address: {
        living_country: editState.living_country,
        street_address: editState.street_address,
        postal_code: editState.postal_code,
        state: editState.state,
        city: editState.city,
      },
    }
    fetchUpdateProfile(editData)
  }
  const onResetPassword = () => {
    clickReset()
    resetPassword.mutate({ email: userProfile?.profile?.email })
  }

  useEffect(() => {
    if (profile) {
      setEditState({
        company_name: profile.name,
        living_country: profile?.legal_address?.living_country?.id,
        street_address: profile?.legal_address?.street_address,
        state: profile?.legal_address?.state,
        city: profile?.legal_address?.city,
        postal_code: profile?.legal_address?.postal_code,
      })
    }
  }, [profile])

  const handleTabChange = (tab) => {
    queryParams.set('tab', tab)
    history.push({ search: queryParams.toString() })
  }

  if (probationPeriodQuery.isLoading || workScheduleQuery.isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <DotWave />
      </Box>
    )
  }

  return (
    <div className="h-100">
      <div className="mb-4">
        <PageTitle>{profile?.name}</PageTitle>
      </div>
      <Tabs onTabChange={handleTabChange} selectedTab={activeTab}>
        <Tab tabId={tabs.managers} title="Managers">
          <ManagersTab />
        </Tab>
        <Tab tabId={tabs.compliance} title="Compliance">
          <ComplianceTab />
        </Tab>

        <Tab
          tabId={tabs.billing_address}
          title="Billing details"
          hidden={!profile?.billing_address}
        >
          <BillingDetails />
        </Tab>

        <Tab tabId={tabs.settings} title="Settings">
          <div className="d-flex flex-column" style={{ maxWidth: 540 }}>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <Typography className="heading_semibold__20 color_black">
                Company Details
              </Typography>
              <Button
                data-testid="ProfilePage-9ACA7F"
                priority="secondary"
                size="small"
                onClick={() => setVisibleEdit(true)}
              >
                Edit
              </Button>
            </div>
            <Avatar
              src={userProfile?.profile?.avatar}
              width={100}
              height={100}
              className="personal-info__avatar mb-4"
            />
            {profile && <List lists={getCompanyDetails(profile)} />}
            <EditModal
              visible={visibleEdit}
              title="Company Details"
              onSave={onSaveEdit}
              loading={updateLoading}
              closeModal={() => setVisibleEdit(false)}
            >
              <EditAvatar
                src={avatarImage || userProfile?.profile?.avatar}
                isLoading={mutationUploadAvatar.isLoading}
                className="personal-info-edit-avatar mb-4"
                onUpload={onAvatarUpload}
              />
              <div className="remo-form-input">
                <Input
                  data-testid="ProfilePage-1114CF"
                  onChange={onChangeEdit}
                  label="Company name"
                  name="company_name"
                  value={editState?.company_name}
                />
              </div>
              <div className="remo-form-input">
                <Select
                  data-testid="ProfilePage-9C47DD"
                  options={countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))}
                  name="living_country"
                  id="living_country"
                  placeholder="e.g United Kingdom"
                  onChange={onChangeLivingCountry}
                  value={editState?.living_country}
                  label="Country"
                />
              </div>
              <div className="remo-form-input">
                <Input
                  data-testid="ProfilePage-41EA54"
                  onChange={onChangeEdit}
                  label="State"
                  name="state"
                  value={editState?.state}
                />
              </div>
              <div className="remo-form-input">
                <Input
                  data-testid="ProfilePage-345F95"
                  onChange={onChangeEdit}
                  label="City"
                  name="city"
                  value={editState?.city}
                />
              </div>
              <div className="remo-form-input">
                <Input
                  data-testid="ProfilePage-6EC75B"
                  onChange={onChangeEdit}
                  label="Address"
                  name="street_address"
                  value={editState?.street_address}
                />
              </div>
              <div className="remo-form-input">
                <Input
                  data-testid="ProfilePage-752084"
                  onChange={onChangeEdit}
                  label="Postal code"
                  name="postal_code"
                  value={editState?.postal_code}
                />
              </div>
            </EditModal>
          </div>
        </Tab>

        <Tab tabId={tabs.eor_settings} title="EoR settings">
          <EorSettings onSubmit={handleEorSettingsSubmit}>
            <Box alignSelf="flex-start">
              <AsyncButton
                isFetching={
                  eorSettingsUpdateMutation.isLoading ||
                  invoiceFieldsUpdateMutation.isLoading
                }
                type="submit"
                form={eorFormId}
                variant="contained"
              >
                Save
              </AsyncButton>
            </Box>
          </EorSettings>
        </Tab>

        <Tab tabId={tabs.country_guides} title="Country guide">
          <Tabs key={2} selectedTab="probation_period">
            {[
              <Tab tabId="probation_period" title="Probation period">
                <ProbationPeriodTab data={probationPeriodQuery.data} />
              </Tab>,
              <Tab tabId="notice_period" title="Notice period">
                <NoticePeriod country={profile?.allowed_countries[0]} />
              </Tab>,
              <Tab tabId="work-schedule" title="Work schedule">
                <WorkScheduleTab data={workScheduleQuery.data} />
              </Tab>,
              <Tab tabId="salary" title="Salary">
                <SalaryTab countryId={profile?.allowed_countries[0]?.id} />
              </Tab>,
            ]}
          </Tabs>
        </Tab>

        <Tab tabId={tabs.password_and_account} title="Password & Account">
          <div>
            <Typography className="heading_semibold__20 mt-3 mb-4">
              Password & Account
            </Typography>
            <Typography className="text_medium__14 mb-2">
              Password reset
            </Typography>
            <Typography
              className="text_light__12 color_text_300"
              style={{ width: 480, fontWeight: 500 }}
            >
              Click the button below to reset password and send the email:
              <Typography className=" color_black">
                {userProfile?.profile?.email}
              </Typography>
            </Typography>
            <Button
              data-testid="ProfilePage-02D0DB"
              className="mt-3"
              priority="secondary"
              size="small"
              disabled={resetClick}
              loading={resetPassword.isLoading}
              onClick={onResetPassword}
            >
              {resetClick ? 'Check your email' : 'Reset password'}
            </Button>
          </div>
        </Tab>

        <Tab tabId={tabs.security} title="Security">
          <SecurityTab />
        </Tab>
      </Tabs>
    </div>
  )
}
