import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { edit, eyeIcon, xMarkIcon } from '@core/icons/icons'
import { capitalizeFirstLetter } from '@core/utils'
import { Avatar } from '@mui/material'
import AdditionalPaymentStatus from '@pages/additional-payments/additional-payments-status/AdditionalPaymentStatus'
import { mapCurrency } from '@remoteam-front/utils/currency'
import moment from 'moment'
import React from 'react'
import { AiOutlineSync } from 'react-icons/ai'

export const payrollsField = (
  onClickView,
  onClickEdit,
  handleDelete,
  onCancel
) => [
  {
    title: 'Team member',
    key: 'name',
    type: 'custom',
    maxWidth: 210,
    minWidth: 210,
    width: 210,
    render: (item) => {
      return (
        <div className="d-flex align-items-center">
          <Avatar src="" />
          <div className="d-flex flex-column ml-4">
            <Typography
              className="text_medium__14"
              style={{ marginBottom: '2px' }}
            >
              {item.name || '-'}
            </Typography>
            <Typography className="text_medium__14 color_grey">
              {item.company_data.title || '-'}
            </Typography>
          </div>
        </div>
      )
    },
  },
  {
    title: 'Company',
    key: 'company',
    type: 'string',
    maxWidth: 125,
    minWidth: 125,
    width: 125,
  },
  {
    title: 'Type',
    key: 'get_ap_type_display',
    type: 'custom',
    maxWidth: 115,
    minWidth: 115,
    width: 115,
    render: (item) => {
      const showIcon = item.is_recurrent
      return (
        <div className="d-flex align-items-center">
          <Typography className="text_medium__14">
            {capitalizeFirstLetter(item.get_ap_type_display)}
          </Typography>
          {showIcon && (
            <div className="ml-2">
              <AiOutlineSync />
            </div>
          )}
        </div>
      )
    },
  },
  {
    title: 'Amount',
    key: 'amount',
    type: 'custom',
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    render: (item) => {
      return (
        <div className="d-flex flex-column">
          <Typography className="text_medium__14 mb-2">
            {item.amount_in_partner_currency || '-'}{' '}
            {mapCurrency(item.partner_currency)}
          </Typography>
          <Typography className="text_medium__14 color_grey">
            {item.amount || '-'} {item.currency.sign}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Employer tax & contributions',
    key: 'tax',
    type: 'custom',
    maxWidth: 140,
    minWidth: 140,
    width: 140,
    render: (item) => {
      return (
        <div className="d-flex flex-column">
          <Typography className="text_medium__14 mb-2">
            {item.tax_in_partner_currency
              ? `${item.tax_in_partner_currency} ${mapCurrency(
                  item.partner_currency
                )}`
              : '-'}
          </Typography>
          <Typography className="text_medium__14 color_grey">
            {item.tax ? `${item.tax} ${item.currency.sign}` : '-'}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Total amount',
    key: 'total_amount',
    type: 'custom',
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    render: (item) => {
      return (
        <div className="d-flex flex-column">
          <Typography className="text_medium__14 mb-2">
            {item.total_amount_in_partner}
          </Typography>
          <Typography className="text_medium__14 color_grey">
            {item.total_amount}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Frequency',
    key: 'recurrence_frequence',
    type: 'custom',
    maxWidth: 135,
    minWidth: 135,
    width: 135,
    render: (item) => {
      const calculateRemainingOccurrences = () => {
        if (!item.is_recurrent) return null
        if (item.recurrence_ending_month === null && !item.occurrences)
          return '∞'

        const monthMultiplier =
          {
            MONTHLY: 1,
            EVERY_2_MONTHS: 2,
            EVERY_3_MONTHS: 3,
          }[item.recurrence_frequency] || 1

        let totalOccurrences
        if (item.occurrences) {
          totalOccurrences = item.occurrences
        } else {
          const startDate = moment(item.recurrence_starting_month)
          const endDate = moment(item.recurrence_ending_month)
          totalOccurrences =
            Math.floor(endDate.diff(startDate, 'months') / monthMultiplier) + 1
        }

        const remaining = totalOccurrences - (item.number_of_occurrences || 0)
        return remaining > 0 ? `${remaining} occ remaining` : ''
      }

      return (
        <div className="d-flex flex-column">
          <Typography className="text_medium__14">
            {item.is_recurrent
              ? capitalizeFirstLetter(item.recurrence_frequency)
              : 'One time'}
          </Typography>
          <Typography className="text_medium__14 color_grey">
            {calculateRemainingOccurrences()}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Submitted',
    key: 'created_at',
    type: 'custom',
    maxWidth: 110,
    minWidth: 110,
    width: 110,
    render: (item) => (
      <Typography className="text_medium__14">
        {item.created_at ? moment(item.created_at).format('D MMM YYYY') : '-'}
      </Typography>
    ),
  },
  {
    title: 'Expected payout',
    key: 'payout_month',
    type: 'custom',
    maxWidth: 90,
    minWidth: 90,
    width: 90,
    render: (item) => {
      return (
        <div className="d-flex align-items-center">
          {item.status === 'Cancelled' ? (
            <Typography className="text_regular__14 color_red mr-2">
              Cancelled
            </Typography>
          ) : (
            <Typography className="text_regular__14 mr-2">
              {item?.payout_date
                ? moment(item.payout_date).format('MMM YYYY')
                : '-'}
            </Typography>
          )}
        </div>
      )
    },
  },
  {
    title: 'Status',
    key: 'status',
    type: 'custom',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
    render: (item) => <AdditionalPaymentStatus status={item.state} />,
  },
  {
    title: 'Action',
    key: 'custom',
    type: 'custom',
    maxWidth: 130,
    minWidth: 130,
    width: 130,
    render: (row) => {
      return (
        <div className="d-flex align-items-center">
          {row.status !== 'Cancelled' &&
            (row.assigned_payroll_state === 'need_changes_from_partner' ||
              row.assigned_payroll_state === 'payroll is not assigned' ||
              row.assigned_payroll_state === 'created') && (
              <Button
                onClick={() => onClickEdit(row)}
                className="text_light__12 px-2 pl-0 ml-2"
                priority="secondary"
                size="small"
                style={{ height: '24px', width: '32px' }}
              >
                <Icon fill="none" icon={edit} />
              </Button>
            )}
          {!row.is_recurrent &&
            row.status !== 'Cancelled' &&
            (row.assigned_payroll_state === 'need_changes_from_partner' ||
              row.assigned_payroll_state === 'payroll is not assigned' ||
              row.assigned_payroll_state === 'created') && (
              <Button
                onClick={() => handleDelete(row.id)}
                className="text_light__12 px-2 pl-0 ml-2"
                priority="secondary"
                size="small"
                style={{ height: '24px', width: '32px' }}
              >
                <Icon fill="none" icon={xMarkIcon} />
              </Button>
            )}
          {row.is_recurrent &&
            row.status !== 'Cancelled' &&
            (row.assigned_payroll_state === 'need_changes_from_partner' ||
              row.assigned_payroll_state === 'payroll is not assigned' ||
              row.assigned_payroll_state === 'created') && (
              <Button
                onClick={() => onCancel(row.id)}
                className="text_light__12 px-2 pl-0 ml-2"
                priority="secondary"
                size="small"
                style={{ height: '24px', width: '32px' }}
              >
                <Icon fill="none" icon={xMarkIcon} />
              </Button>
            )}
          <Button
            onClick={() => onClickView(row)}
            className="text_light__12 px-2 pl-0 ml-2"
            priority="secondary"
            size="small"
            style={{ height: '24px', width: '32px' }}
          >
            <Icon fill="none" icon={eyeIcon} />
          </Button>
        </div>
      )
    },
  },
]
