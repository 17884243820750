import { useApp } from '@core/context'
import { KeyboardArrowLeft } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import {
  eorSettingsUpdate,
  invoiceFieldsUpdate,
} from '@remoteam-front/services'
import { InvoiceFields, PartnerEorSettings } from '@remoteam-front/types/v2'
import { Logo, StepContainer, StepIcon, theme } from '@remoteam-front/ui'
import { ClearFilledIcon } from '@remoteam-front/ui/icons'
import {
  getOnboardingChecklist,
  setCheckCountryGuide,
} from '@services/profile.service'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import {
  eorFormId,
  EorFormType,
  EorSettings,
} from '../../../../../components/eor-settings/eor-settings.component'
import { MinCountrySalary } from '../min-country-salary/min-country-salary.component'
import { NoticePeriod } from '../notice-period/notice-period.component'
import { ProbationPeriod } from '../probation-period/probation-period.component'
import { RequiredDocuments } from '../required-documents/required-documents.component'
import { WorkSchedule } from '../work-schedule/work-schedule.component'
import { Styled } from './country-guide-stepper.styles'

type Props = {
  onClose(): void
}

export const CountryGuideStepper = ({ onClose }: Props) => {
  const { profile } = useApp() as any

  const [activeStep, setActiveStep] = useState<number>(0)
  const matchesDownMobile = useMediaQuery(theme.breakpoints.down('mobile'))

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)
  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

  const queryClient = useQueryClient()

  const setCheckCountryGuideMutation = useMutation({
    mutationFn: () => setCheckCountryGuide(profile.id),
    onSuccess: () => {
      queryClient.refetchQueries(getOnboardingChecklist.key)
      onClose()
    },
  })

  const eorSettingsUpdateMutation = useMutation({
    mutationFn: (body: PartnerEorSettings) =>
      eorSettingsUpdate(profile!.partner_profile_id, body),
  })

  const invoiceFieldsUpdateMutation = useMutation({
    mutationFn: (body: InvoiceFields) =>
      invoiceFieldsUpdate(profile!.partner_profile_id, body),
  })

  const handleEorSettingsSubmit = ({
    eorSettings,
    invoiceFields,
  }: EorFormType) => {
    eorSettingsUpdateMutation.mutateAsync(eorSettings).then(() => {
      const { payroll_frequencies } = invoiceFields
      invoiceFieldsUpdateMutation.mutate({ payroll_frequencies })
    })
    handleNext()
  }

  const [steps] = useState([
    {
      label: 'EoR settings',
      content: (
        <EorSettings onSubmit={handleEorSettingsSubmit}>
          <Box alignSelf="center">
            <Button type="submit" form={eorFormId} variant="contained">
              Continue
            </Button>
          </Box>
        </EorSettings>
      ),
    },
    {
      label: 'Probation period',
      content: (
        <ProbationPeriod
          countryId={profile?.allowed_countries[0]?.id}
          onNext={handleNext}
        />
      ),
    },
    {
      label: 'Notice period',
      content: (
        <NoticePeriod
          country={profile?.allowed_countries[0]}
          onNext={handleNext}
        />
      ),
    },
    {
      label: 'Working schedule',
      content: (
        <WorkSchedule
          countryId={profile?.allowed_countries[0]?.id}
          onNext={handleNext}
        />
      ),
    },
    {
      label: 'Salary',
      content: (
        <MinCountrySalary
          countryId={profile?.allowed_countries[0]?.id}
          onNext={handleNext}
        />
      ),
    },
    {
      label: 'Required documents',
      content: (
        <RequiredDocuments
          isLoading={setCheckCountryGuideMutation.isLoading}
          onSave={setCheckCountryGuideMutation.mutate}
        />
      ),
    },
  ])

  return (
    <Dialog open fullScreen onClose={onClose}>
      <Styled.Root>
        <Styled.Sidebar>
          <Styled.SidebarHeader>
            <Styled.LogoContainer>
              <Logo />
            </Styled.LogoContainer>
            <Typography variant="h2_strong" mb={matchesDownMobile ? 1.5 : 3}>
              EoR settings & Country guides
            </Typography>
            <Typography variant="body1" color="product.secondaryVariant">
              Please provide details so we can begin collaborating together.
            </Typography>
          </Styled.SidebarHeader>
          <Styled.SidebarBody>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map(({ label }, index) => (
                <Step key={label}>
                  <StepLabel
                    optional={
                      <Typography variant="caption">
                        Step {index + 1}
                      </Typography>
                    }
                    StepIconComponent={StepIcon}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Styled.SidebarBody>
        </Styled.Sidebar>
        <Styled.Main>
          <Styled.MainHeader>
            <IconButton
              data-testid="country-guide-stepper.component-2A22D8"
              color="secondary"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <Typography
              data-testid="country-guide-stepper.component-A06486"
              variant="h2_strong"
            >
              {steps[activeStep].label}
            </Typography>
            <IconButton
              data-testid="country-guide-stepper.component-EC7470"
              color="secondary"
              onClick={onClose}
            >
              <ClearFilledIcon />
            </IconButton>
          </Styled.MainHeader>
          <Styled.MainBody>
            {steps.map(({ label, content }, index) => (
              <StepContainer key={label} visible={activeStep === index}>
                {content}
              </StepContainer>
            ))}
          </Styled.MainBody>
        </Styled.Main>
      </Styled.Root>
    </Dialog>
  )
}
